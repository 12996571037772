import Vue from 'vue'
import VueRouter from 'vue-router'

const Login = () => import('../views/LoginView.vue')
const PassVerify = () => import('../views/PassVerifyView.vue')
// const FaceComparison = () => import('../views/FaceComparisonView')
// const FaceVerify = () => import('../views/FaceVerifyView')
const faceVerifyContrast = () => import('../views/faceVerifyContrastView')
const PersonalInfoConfirm = () => import('../views/PersonalInfoConfirmView.vue')
const InstructionsConfirm = () => import('../views/InstructionsConfirmView.vue')
const CommitmentConfirm = () => import('../views/CommitmentConfirmView.vue')
const Prepare = () => import('../views/PrepareView.vue')
const Examine = () => import('../views/ExamineView.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'PassVerify',
    component: PassVerify
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/faceComparison',
    name: 'FaceComparison',
    component: faceVerifyContrast
  },
  {
    path: '/personalInfoConfirm',
    name: 'PersonalInfoConfirm',
    component: PersonalInfoConfirm
  },
  {
    path: '/instructionsConfirm',
    name: 'InstructionsConfirm',
    component: InstructionsConfirm
  },
  {
    path: '/commitmentConfirm',
    name: 'CommitmentConfirm',
    component: CommitmentConfirm
  },
  {
    path: '/prepare',
    name: 'Prepare',
    component: Prepare
  },
  {
    path: '/examine',
    name: 'Examine',
    component: Examine
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

