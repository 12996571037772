import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts

import axios from 'axios'
Vue.prototype.$axios = axios;

import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video

import webSocket from './components/webSocket.js'
Vue.prototype.$webSocket = webSocket

/* eslint-disable */
// require ('./jsvm_all.js'); // 引入阿里金融级实人认证客户端脚本

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
