export default {
    url: '', // url 地址
    ws: {},//websocket对象
    heartInterval: 0,
    delay: 1500,//重连延迟，单位：毫秒
    onOpenFn: function () { },
    onMessageFn: function () { },
    onErrorFn: function () { },
    onCloseFn: function () { },

    //设置websocket对象方法
    init: function (url, onOpenFn, onMessageFn, onErrorFn, onCloseFn) {
        this.url = url;
        this.onOpenFn = onOpenFn;
        this.onMessageFn = onMessageFn;
        this.onErrorFn = onErrorFn;
        this.onCloseFn = onCloseFn;

        if (this.ws !== null) {
            if (this.ws.readyState == 1) {
                this.ws.onopen = function () { };
                this.ws.onerror = function () { };
                this.ws.onclose = function () { };
                this.ws.onmessage = function () { };
                clearInterval(this.heartInterval);
                this.ws.close();
            }
            this.ws = {};
        }

        this.createWebSocket();
    },
    createWebSocket: function () {
        try{
            let that = this;
            that.ws = new WebSocket(that.url);
    
            that.ws.onopen = function () {
                that.heartInterval = setInterval(() => {
                    //判断当前webscokt状态
                    if (that.ws.readyState == 1) {
                        console.log('发送 ping：' + Date());
                        //调用发送方法
                        that.sendMsg(
                            'ping'
                        );
                    }
                }, 30000);
                that.onOpenFn();
            };
            that.ws.onmessage = function (res) {
                console.log("收到消息...开始处理");
                try {
                    let data = JSON.parse(res.data);
                    that.onMessageFn(data);
                } catch (e) {
                    console.log("处理收到的消息时发生错误...");
                    console.log("收到的消息是:" + res.data);
                }
            };
            that.ws.onerror = function () {
                console.log("连接已出错...重新连接服务器");
                // setTimeout(() => {
                //     that.createWebSocket();
                // }, that.delay);
                that.onErrorFn();
            };
            that.ws.onclose = function () {
                console.log("连接已关闭...重新连接服务器");
                setTimeout(() => {
                    clearInterval(that.heartInterval);
                    that.createWebSocket();
                }, that.delay);
                that.onCloseFn();
            };
        } catch(e) {
            console.log('连接服务器失败...');
        }
    },
    //设置延迟方法
    setDelay: function (newDelay) {
        this.delay = newDelay;
    },
    //发送websocket信息方法
    sendMsg: function (message) {
        this.ws.send(message)
    },
}